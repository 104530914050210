function makeDonation(callbackUrl){

    PayPal.Donation.Button({
       
        env: 'production',
        //env: 'sandbox',
        hosted_button_id: '9F4Y54V62ETX2',
        //hosted_button_id: 'H9GSW7ZSW3AKJ',
        //hosted_button_id: 'CJXZDEB8DUV6N',
        // business: 'YOUR_EMAIL_OR_PAYERID',
        image: {
            src: 'https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif',
            title: 'PayPal - The safer, easier way to pay online!',
            alt: 'Donate with PayPal button'
        },
        onComplete: function (params) {
            // Your onComplete handler
            console.log(params);
            console.log("Begin sending response tran");
            location.reload();
            let xhr = new XMLHttpRequest();
          xhr.open("POST", callbackUrl);
            xhr.setRequestHeader("Accept", "application/json");
            xhr.setRequestHeader("Content-Type", "application/json");

            xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                console.log(xhr.status);
                console.log(xhr.responseText);
            }};
            var data = {};
            data.Amount =  params.amt;
            data.TransactionId = params.tx;
            data.CreatedDate = new Date();
            data.Email = params.tx;
            data.Phone = params.tx;
            data.Comment = params.tx;
            /*
            let data2 = `{ 
                "amount":` + params.amt + ` ,                                               
                "transactionId": "` + params.tx ` "

                "amount": 25,
  "createdDate": "2023-05-20T19:18:58.628Z",
  "email": "string@string.com",
  "phoneNumber": "1234567890",
  "address": "123 West Seattle",
  "transactionId": "2TID12346"
            }`;
            */
            console.log(JSON.stringify(data));
            xhr.send(JSON.stringify(data));
            console.log("Begin sending response tran");
        },
    }).render('#paypal-donate-button-container');
}

let flag3 = false;
const notificationHandler = () => {
    let notification = document.getElementById("notification");
    let checdiv = document.getElementById("chec-div");
    if (!flag3) {
        notification.classList.add("translate-x-full");
        notification.classList.remove("translate-x-0");
        setTimeout(function () {
            checdiv.classList.add("hidden");
        }, 1000);
        flag3 = true;
    } else {
        setTimeout(function () {
            notification.classList.remove("translate-x-full");
            notification.classList.add("translate-x-0");
        }, 1000);
        checdiv.classList.remove("hidden");
        flag3 = false;
    }
};
//hosted_button_id: 'CJXZDEB8DUV6N',
window.addEventListener('load', function(){

    const config = {
        type: 'carousel',
        startAt: 0,
        perView: 4,
        gap: 32,
        breakpoints: {
          1280: {
            perView: 3,
          },
          1024: {
            perView: 2,
          },
          768: {
            perView: 1,
          }
        }
      }
     

      
});
